import React from 'react';
import PurpleSection from '../../sections/Purple';
import NaturalPage from '../../layout/components/NaturalPage';

import FullBlackSection from '../../sections/FullBlack';
import { Section, Filter, Ad, Image } from '../../components';
import PostList from '../../blocks/PostList';
import Message from '../../components/Message';

class InfoPage extends NaturalPage {
  state: any;
  props: any;

  constructor(props) {
    super(props);

    this.state = {
      inputValue: null,
      postBlocks: props.content.infoPages || [],
    };
  }

  handleFilter(e) {
    const { value } = e.target;
    const { infoPages = [] } = this.props.content;

    const postBlocks = infoPages.map(({ name, pages }) => ({
      name,
      pages: pages.filter(({ title }) =>
        [name, title].some((text: string) => (text ? text.toLowerCase().includes(value.toLowerCase()) : false)),
      ),
    }));

    this.setState({ inputValue: value, postBlocks });
  }

  renderChildren() {
    const { postBlocks } = this.state;
    const { title, footerBlocks, infoPages = [], adCode, adImage } = this.props.content;

    const footerBlock = footerBlocks[footerBlocks.length - 1];

    const results = postBlocks.reduce((acc, { pages }) => (acc += pages.length), 0);

    return (
      <>
        <FullBlackSection
          isFirstSection={true}
          pageHasAnnouncement={this.pageHasAnnouncement()}
          natural={true}
          noFooterBlocks={!footerBlock}
        >
          <Filter.TextInputHeader
            title={title}
            placeholder="Type here to search"
            value={this.state.inputValue}
            onChange={(e) => this.handleFilter(e)}
          />

          <Section.Content>
            <Filter.Content>
              {results ? (
                postBlocks.map(({ name, pages }, index) => pages.length && <PostList key={`post-${index}`} title={name} items={pages} />)
              ) : (
                <Message value={`Sorry there are no results for "${this.state.inputValue}"`} />
              )}
            </Filter.Content>

            <Section.Sidebar>
              <Filter.Sidebar
                items={infoPages.map(({ name }) => name)}
                onClick={() => this.setState({ inputValue: '', postBlocks: infoPages })}
                pageHasAnnouncement={this.pageHasAnnouncement()}
              />
              {adCode && <Ad {...adCode} />}
              {adImage && (
                <a href={adImage.link.url} target="_blank">
                  <Image {...adImage.image} />
                </a>
              )}
            </Section.Sidebar>
          </Section.Content>

          {footerBlocks &&
            footerBlocks.map((block, index) => {
              if (index < footerBlocks.length - 1) {
                return this.renderBlock(block, index);
              }
            })}
        </FullBlackSection>

        {footerBlock && <PurpleSection>{this.renderBlock(footerBlock)}</PurpleSection>}
      </>
    );
  }
}

export default InfoPage;
